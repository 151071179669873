import * as React from 'react';
import {useEffect, useRef, useState,useContext} from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Divider,
    Grow,
    ListSubheader,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {getEnvName, LIVEOPS_ENV, PROD} from "./Utils/CommonUtils";
import {useLocation, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {SnackBarContext} from "../core/context/snackBarProvider";

export default function SplitButton(props) {
    const {options, switchEnv, color, variant} = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);

    const optionsKeys = Object.keys(options)
    const env1Name = getEnvName('env', sourceMode, searchParams);
    const env2Name = getEnvName('env2', sourceMode, searchParams);
    const [selectedIndex, setSelectedIndex] = useState(optionsKeys.indexOf(env1Name) === -1 || env2Name === PROD ?
        LIVEOPS_ENV : env1Name);
    //
    // console.log('selectedIndex', selectedIndex)
    // console.log('env1Name', env1Name)
    // console.log('env2Name', env2Name)
    const location = useLocation()


    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    useEffect(() => {
        const env1Name = getEnvName('env', sourceMode, searchParams);
        const env2Name = getEnvName('env2', sourceMode, searchParams);
        setSelectedIndex(optionsKeys.indexOf(env1Name) === -1 || env2Name === PROD ? LIVEOPS_ENV : env1Name)
    }, [sourceMode, searchParams])



    const canSwitchValidation = () => {

        if(window.location.pathname.indexOf("/creator")>=0) {
            //let h = window.location.hash.split('#')
            //if(parseInt(h[1])>=1) {
                addAlert({text: 'You can\'t change environment while creating campaign', type: 'warning'})
                return false;
            //}
        }
        return true;
    }

    const handleClick = () => {
        if(!canSwitchValidation()) return false;
        switchEnv(selectedIndex);
    };

    const handleMenuItemClick = (event, index) => {
        if(!canSwitchValidation()) return false;

        setSelectedIndex(index);
        switchEnv(index)
        setOpen(false);
    };

    const handleToggle = () => {
        if(!canSwitchValidation()) return false;

        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const sortByName = (a, b) => {
        return a[1].name.localeCompare(b[1].name);
    }

    return (
        <React.Fragment>
            <ButtonGroup
                ref={anchorRef} aria-label="split button">
                <Button variant={variant}
                        color={color}
                        onClick={handleClick}>

                    {selectedIndex}</Button>
                <Button
                    variant={variant}
                    color={color}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <ListSubheader>Reserved</ListSubheader>
                                    {Object.entries(options)
                                        .sort((a, b) => {
                                            return b[1].reservedPriority - a[1].reservedPriority;
                                        })
                                        .filter(item => item[1].reserved === true)

                                        .map((option, index) => (
                                            <MenuItem
                                                key={option[0]}
                                                selected={option[0] === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, option[0])}
                                            >
                                                {option[0]}
                                            </MenuItem>
                                    ))}
                                    <ListSubheader>Custom</ListSubheader>

                                    {Object.entries(options)
                                        .sort(sortByName)
                                        .filter(item => item[1].reserved === false)
                                        .map((option, index) => (
                                        <MenuItem
                                            key={option[0]}
                                            selected={option[0] === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, option[0])}
                                        >
                                            {option[0]}
                                        </MenuItem>
                                    ))}

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}