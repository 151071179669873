import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Link,
    Paper,
    Step,
    StepLabel,
    Stepper, Tooltip,
    Typography
} from "@mui/material";
import BasicStep from "./CampaignSteps/BasicStep";
import {Fragment, useEffect, useRef, useState} from "react";
import DateStep from "./CampaignSteps/DateStep";
import AudienceStep, {POSSIBLE_OPERATORS} from "./CampaignSteps/AudienceStep";
import JsonViewer from "../Utils/JsonViewer";
import dayjs from "dayjs";
import {useFindCampaignByIdQuery, useSaveCampaignMutation} from "../../core/reducers/campaign";
import {getColor, getEnvName, hasContent, isEmpty, saveHistory} from "../Utils/CommonUtils";
import SummaryStep from "./CampaignSteps/SummaryStep";
import {useNavigate} from "react-router";
import {validateNumber, validateVersion} from "../Utils/Validator";
import ReactJson from "react-json-view";
import {MODE} from "./campaignMode";
import {Alert, LoadingButton} from "@mui/lab";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {SEARCH_TYPES} from "../../core/reducers/typeFilter";
import {useGetAllCampaignTypesQuery} from "../../core/reducers/campaignType";
import {useFindTemplateByIdQuery, useSaveTemplateMutation} from "../../core/reducers/templates";

const STEPS = {
    STEP_ONE: 'basic',
    DISTRIBUTION: 'distribution',
    AUDIENCE: 'audience',
    FINAL_STEP: 'summary',
}
const stepSuccession = [STEPS.STEP_ONE, STEPS.DISTRIBUTION, STEPS.AUDIENCE, STEPS.FINAL_STEP];
const stepRequiredInformationMap = {
    [STEPS.STEP_ONE]: ['campaignId', 'priority', 'offerType'],
    [STEPS.DISTRIBUTION]: ['distributionType'],
    [STEPS.AUDIENCE]: [],
    [STEPS.FINAL_STEP]: []
}
const AUDIENCE_PREFIX = "Audience";
export const getCurrentParam = (campaign, paramIndex, currentAudienceIndex) => {
    return campaign?.audiences[currentAudienceIndex].parameters[paramIndex]
}

export const getCurrentParamAllowedValues = (campaign, paramIndex, currentAudienceIndex, defaultParameters) => {
    let param = getCurrentParam(campaign, paramIndex, currentAudienceIndex);
    if (!param.parameterData.parameterName) return [];
    let paramObj = defaultParameters.filter(p => p.parameterName === param.parameterData.parameterName)
    if(paramObj.length == 0) return []
    return paramObj[0].allowedValues;
}

const currentMoment = dayjs(new Date()).set('second', 0);

export class Campaign {
    campaignId = "";
    groupId = "";
    fickle = false;
    repeatable = false;
    priority = "0";
    offerType = "";
    bundleId = "";
    timeZone = "";
    from = currentMoment;
    till = currentMoment;
    distributionType = "";
    durationHour = "";
    audiences =
        [
            {
                name: `Audience 1`,
                count: 1,
                parameters: [
                    new ParametersSet(),
                ]
            }
        ];
}
export class CampaignTemplate {
    campaignId = "";
    groupId = "";
    fickle = false;
    priority = "";
    offerType = "";
    bundleId = "";
    timeZone = "";
    from = currentMoment;
    till = currentMoment;
    distributionType = "";
    durationHour = "";
    schedule = {
        delayDays: 0,
        till: null
    }
    audiences =
        [
            {
                name: `Audience 1`,
                count: 1,
                parameters: [
                    new ParametersSet(),
                ]
            }
        ];
}

export class VersionNumericParameterSet {
    constructor(allowedRules, parameterName, paramFromValue = "", paramToValue = "", fromIncl = true, toIncl = false, operator = 'gt') {
        this.paramFromValue = paramFromValue;
        this.parameterData.allowedRules = allowedRules;
        this.paramToValue = paramToValue;
        this.parameterData.parameterName = parameterName;
        this.fromIncl = fromIncl;
        this.toIncl = toIncl;
        this.operator = operator;
    }

    parameterData = {
        allowedRules: [],
        parameterName: "",
    };
}

export const isVersionNumericParameter = (parameter) => {
    return parameter.allowedRules.includes('NUMBER_RANGE') ||
        parameter.allowedRules.includes('VERSION_RANGE');
}

export class ParametersSet {
    constructor(allowedRules, allowedValues, isMain, parameterName, operator, paramValue = '', offerId = '', offsetValue = '') {
        this.parameterData.allowedRules = allowedRules;
        this.parameterData.allowedValues = allowedValues;
        this.parameterData.isMain = isMain;
        this.parameterData.parameterName = parameterName;
        this.operator = operator;
        this.paramValue = paramValue;
        this.offerId = offerId;
        this.offsetValue = offsetValue;
    }

    parameterData = {
        allowedRules: [],
        allowedValues: [],
        isMain: false,
        parameterName: "",
    };
    operator = "";
    paramValue = "";
}

export default function CampaignForm(props) {
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const locationParams = Object.fromEntries(searchParams.entries());

    class NumericRange {
        parameter: '';
        from: '';
        fromOperator: '';
        to: '';
        toOperator: ''
    }

    class AudienceForSave {
        includeAll = [];
        includeAny = [];
        excludeAll = [];
        excludeAny = [];
        numberRange = [];
        versionRange = [];
        offerTypeInactiveRule = [];
        offerInactiveRule = [];
        offerNotInProfile = [];
        offerInProfile = [];
        profileOffset = [];
    }

    const [audienceCounter, setAudienceCounter] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [currentCampaign, setCurrentCampaign] = useState(props.campaign);
    const [currentCampaignForCheck, setCurrentCampaignForCheck] = useState(props.campaign);

    const [saveCampaign, saveCampaignResponse] = useSaveCampaignMutation();
    const [saveTemplateCampaign, saveTemplateCampaignResponse] = useSaveTemplateMutation();
    const responseObject = props.template ? saveTemplateCampaignResponse : saveCampaignResponse

    const [errorFields, setErrorFields] = useState(new Set());
    const navigate = useNavigate();
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [campaignForSearch, setCampaignForSearch] = useState('');
    const [inputNotVerified, setInputNotVerified] = useState(activeStep === 0);
    const [errorAudienceIndexes, setErrorAudienceIndexes] = useState([])
    const [typeParameters, setTypeParameters] = useState(
        {
            announceDuration: 0,
            extraDuration: 0,
        }
    );

    const {
        data: types,
        status: typeStatus
    } = useGetAllCampaignTypesQuery()

    useEffect(() => {
        if ((props.mode === MODE.UPDATE && types) || (types && !isEmpty(currentCampaign.offerType))) {
            debugger
            let type = types[currentCampaign.offerType];
            setTypeParameters({
                announceDuration: type?.announceDuration,
                extraDuration: type?.extraDuration,
            });
            if(props.mode == MODE.CLONE) setCurrentCampaign({
                ...currentCampaign,
                regexp: type?.regexp
            });
        }
    }, [typeStatus, currentCampaign.offerType])


    const sourceMode = useSelector((state) => state.data.SourceMode);

    useEffect(() => {
        if (props.mode === MODE.UPDATE) {
            saveHistory(props.campaign.campaignId, getEnvName('env', sourceMode, searchParams), SEARCH_TYPES.CAMPAIGNS)
        }
    }, [responseObject])


    const prevID = useRef();
    useEffect(() => {
        if(prevID.current==undefined || prevID.current != currentCampaignForCheck.campaignId) {
            prevID.current = currentCampaignForCheck.campaignId

        }
    },[currentCampaignForCheck])

    // const {data, isError, error, isSuccess, isLoading, status} = useFindCampaignByIdQuery({
    //     ev: getEnvName('env', sourceMode, searchParams),
    //     id: currentCampaignForCheck.campaignId
    // }, {skip: props.mode === MODE.UPDATE || !currentCampaignForCheck.campaignId});
    const findCampaignResult = useFindCampaignByIdQuery({
        ev: getEnvName('env', sourceMode, searchParams),
        id: currentCampaignForCheck.campaignId
    }, { skip: props.template || props.mode === MODE.UPDATE || !currentCampaignForCheck.campaignId });
console.log('props.template')
console.log(props.template)
    const findTemplateResult = useFindTemplateByIdQuery({
        ev: getEnvName('env', sourceMode, searchParams),
        id: currentCampaignForCheck.campaignId
    }, { skip: !props.template || props.mode === MODE.UPDATE || !currentCampaignForCheck.campaignId });

    const { data, isError, error, isSuccess, isLoading, status } = props.template ? findTemplateResult : findCampaignResult;


    const [beforeSaveDialogOpen, setBeforeSaveDialogOpen] = useState(false);
    const [campaignForSave, setCampaignForSave] = useState({});

    const interactionTypeFilled = types!=undefined&&currentCampaign!=undefined&&currentCampaign.offerType&&types.hasOwnProperty(currentCampaign.offerType) ?
        (types[currentCampaign.offerType].interactionType=='TOURNAMENT'
            &&isEmpty(currentCampaign.matchmakingType)
            &&types[currentCampaign.offerType]?.matchmakingTypes!=null
            &&types[currentCampaign.offerType]?.matchmakingTypes.length>0
        ) : true;

    useEffect(() => {
        if (responseObject && responseObject.status === 'fulfilled') {
            setSuccessDialogOpen(true);
        } else if (responseObject && responseObject.isError) {
            setErrorDialogOpen(true)
        }
        if (currentCampaign.campaignId && props.mode !== MODE.UPDATE) {
            let newErrorFields = new Set(errorFields);
            if (status === 'pending') {
                newErrorFields.delete('campaignId');
            } else if (status !== 'uninitialized' && (isSuccess || !(isError && error.data.message.includes('not found')))) {
                newErrorFields.add('campaignId');
            } else {
                newErrorFields.delete('campaignId');
            }
            setErrorFields(newErrorFields);
        }

        if (currentCampaign.campaignId.indexOf("clone") >= 0 && !errorFields.has('clone')) {
            setErrorFields(errorFields.add("clone"))
        } else if (errorFields.has('clone')) {
            errorFields.delete("clone")
            setErrorFields(errorFields)
        }

    }, [responseObject, currentCampaign.campaignId, status])

    useEffect(() => {
        setErrorAudienceIndexes(findErrorAudiences());
    }, [currentCampaign, currentCampaign.audiences])

    useEffect(() => {
        if(locationParams.hasOwnProperty('bundleId')) {
            if(currentCampaign.bundleId != locationParams['bundleId']) {
                setCurrentCampaign({...currentCampaign, bundleId: locationParams['bundleId']})
            }
        }
        if(locationParams.hasOwnProperty('type')) {
            if(currentCampaign.offerType != locationParams['type']) {
                setCurrentCampaign({...currentCampaign, offerType: locationParams['type']})
            }
        }
    }, [currentCampaign])

    useEffect(() => {
        if (activeStep === 0) {
            const delayInputTimeoutId = setTimeout(() => {
                setCurrentCampaignForCheck(currentCampaign);
                setInputNotVerified(false)
            }, 300);
            setInputNotVerified(true)
            return () => clearTimeout(delayInputTimeoutId);
        }
    }, [currentCampaign, 300])

    function handleChangeScheduleTill(date, field) {
        try {
            date = date.set('second', 0)
            let d = dayjs(new Date(date.valueOf()));
            let newCampaign = {...currentCampaign};
            newCampaign.schedule[field] = d;
            newCampaign.schedule[field + 'Instant'] = Date.parse(date.toISOString().replace('Z', currentCampaign.timeZone));
            setCurrentCampaign(newCampaign)
        } catch (e) {
            let newCampaign = {...currentCampaign};
            newCampaign.schedule[field] = null;
            newCampaign.schedule[field + 'Instant'] = null;
            setCurrentCampaign(newCampaign);
        }

        // const from = dayjs(currentCampaign.from);
        // if (date.isBefore(from)) {
        //     setErrorFields(errorFields.add("scheduletill"))
        // } else {
        //     errorFields.delete("scheduletill")
        // }
    }
    function handleChangeSchedule(event) {

        let newCampaign = {...currentCampaign};
        newCampaign.schedule[event.target.name] = event.target.value;
        setCurrentCampaign(newCampaign);
    }

    function handleChange(event) {
        const numRegex = /^[0-9\b]+$/;
        let newCampaign = {...currentCampaign};

        if (event.target.name === 'priority') {
            if(!numRegex.test(event.target.value)) {
                setErrorFields(errorFields.add("priority"))
            } else {
                errorFields.delete("priority")
            }
        }
        if (event.target.name === 'campaignId') {
            newCampaign.groupId = event.target.value;
            if (newCampaign.campaignId.indexOf("clone") >= 0 && !errorFields.has('clone')) {
                setErrorFields(errorFields.add("clone"))
            } else if (errorFields.has('clone')) {
                errorFields.delete("clone")
                setErrorFields(errorFields)
            }
        }
        if (event.target.name === 'durationHour' && event.target.value != '' && !numRegex.test(event.target.value)) {
            return;
        }

        newCampaign[event.target.name] = event.target.value;

        if (event.target.name === 'distributionType') {
            newCampaign.durationHour = '';
            if(event.target.value == 'permanent') {
                newCampaign.till = dayjs(new Date("3000-01-01 03:00:00"));
                newCampaign.durationHour = 0
            }
        }

        setCurrentCampaign(newCampaign);

        validateAudience()
    }

    function handleTimeZoneChange(value) {
        let newCampaign = {...currentCampaign};
        newCampaign.timeZone = value;
        setCurrentCampaign(newCampaign);
    }

    function handleDateChange(date, field) {
        try {
            date = date.set('second', 0)
            let d = dayjs(new Date(date.valueOf()));
            let newCampaign = {...currentCampaign};
            newCampaign[field] = d;
            newCampaign[field + 'Instant'] = Date.parse(date.toISOString().replace('Z', currentCampaign.timeZone));
            setCurrentCampaign(newCampaign)
        } catch (e) {

        }

    }

    function handleOfferTypeChange(value, types) {
        let newCampaign = {...currentCampaign};

        newCampaign.offerType = value;
        if(types.hasOwnProperty(value)) { newCampaign.regexp = types[value].regexp; }

        setCurrentCampaign(newCampaign);
    }

    function handleMatchmakingTypeChange(event, value) {
        let newCampaign = {...currentCampaign};
        newCampaign.matchmakingType = value;
        if(isEmpty(value)) {
            setErrorFields(errorFields.add("matchmakingType"))
        } else {
            errorFields.delete("matchmakingType")
        }
        setCurrentCampaign(newCampaign);
    }

    function handleBundleChange(value) {
        // if (!value) {
        //     return
        // }
        let newCampaign = {...currentCampaign};
        newCampaign.bundleId = value;
        setCurrentCampaign(newCampaign);
    }

    function handleBundleManualInputChange(e) {
        let newCampaign = {...currentCampaign};
        newCampaign.bundleId = e.target.value;
        setCurrentCampaign(newCampaign);
    }

    function handleParameterDataChange(params, value, paramIndex, currentAudienceIndex) {
        let newErrorFields = new Set([...errorFields].filter(o => (o.audience === undefined)));
        setErrorFields(newErrorFields);

        let newCampaign = {...currentCampaign};
        let param = params.filter(p => p.parameterName === value)[0];
        if (isVersionNumericParameter(param)) {
            newCampaign.audiences[currentAudienceIndex].parameters[paramIndex] = new VersionNumericParameterSet();
        } else {
            newCampaign.audiences[currentAudienceIndex].parameters[paramIndex] = new ParametersSet();
        }
        let firstRule = param.allowedRules[0];
        let operator = typeof POSSIBLE_OPERATORS.get(firstRule) === 'string' ? POSSIBLE_OPERATORS.get(firstRule) : POSSIBLE_OPERATORS.get(firstRule)[0];
        getCurrentParam(newCampaign, paramIndex, currentAudienceIndex).parameterData = param;
        newCampaign.audiences[currentAudienceIndex].parameters[paramIndex].operator = operator
        setCurrentCampaign(newCampaign);
        validateAudience()
    }

    function handleParameterOperatorChange(value, paramIndex, currentAudienceIndex) {

        let newCampaign = {...currentCampaign};
        getCurrentParam(currentCampaign, paramIndex, currentAudienceIndex).operator = value;
        setCurrentCampaign(newCampaign);
    }

    function handleParameterValueChange(value, paramIndex, currentAudienceIndex) {
        let newCampaign = {...currentCampaign};
        let param = getCurrentParam(newCampaign, paramIndex, currentAudienceIndex);
        param.paramValue = value;
        setCurrentCampaign(newCampaign);
        validateAudience()
    }

    function handleManualInput(e, paramIndex, currentAudienceIndex) {
        let newCampaign = {...currentCampaign};
        const currentParam = getCurrentParam(newCampaign, paramIndex, currentAudienceIndex);
        currentParam.paramValue = e.target.value;
        setCurrentCampaign(newCampaign);
        validateAudience()
    }

    function handleManualInputForOffset(e, fieldName, paramIndex, currentAudienceIndex) {
        let newCampaign = {...currentCampaign};
        const currentParam = getCurrentParam(newCampaign, paramIndex, currentAudienceIndex);
        currentParam[fieldName] = e.target.value;
        setCurrentCampaign(newCampaign);
    }

    function handleAddNewAudience(index) {
        let newAudiences = [...currentCampaign.audiences];
        let absentNumbers = findAbsentAudiences(newAudiences);
        let newAudienceCounter = absentNumbers.length > 0 ? absentNumbers[0] : index + 1

        setAudienceCounter(newAudienceCounter);
        newAudiences.push({
            'name': `${AUDIENCE_PREFIX} ${newAudienceCounter}`,
            'count': newAudienceCounter,
            'parameters': [
                new ParametersSet([], [], null, "", "", "")
            ]
        });
        handleChange({
            'target': {
                'name': 'audiences',
                'value': newAudiences
            }
        })
    }

    function findAbsentAudiences(audiences) {
        let numbers = audiences.map(a => a.count).sort();
        let absentNumbers = [];
        for (let i = 1; i < numbers[numbers.length - 1]; i++) {
            if (!numbers.includes(i)) {
                absentNumbers.push(i);
            }
        }
        return absentNumbers;
    }


    function handleDeleteAudience(index) {
        if (currentCampaign.audiences.length > 1) {
            let newAudiences = [...currentCampaign.audiences]
            newAudiences.splice(index, 1)
            let newErrorFields = new Set(errorFields);
            setErrorFields(new Set([...newErrorFields].filter(error => !error?.audience !== index)));

            handleChange({
                'target': {
                    'name': 'audiences',
                    'value': newAudiences
                }
            })
        }
    }

    function autocompleteSomeFields() {
        switch (stepSuccession[activeStep]) {
            case STEPS.STEP_ONE:
                currentCampaign.bundleId = currentCampaign.bundleId ? currentCampaign.bundleId : currentCampaign.campaignId;
                break
        }
    }

    function handleAddNewParams(audienceIndex) {
        let audiences = [...currentCampaign.audiences];
        let currentAudience = audiences[audienceIndex]
        let newAudienceParameters = currentAudience.parameters;
        newAudienceParameters.push(new ParametersSet())
        audiences[audienceIndex].parameters = newAudienceParameters
        handleChange({
            'target': {
                'name': 'audiences',
                'value': audiences
            }
        })
    }

    function handleDeleteParams(audienceIndex, paramIndex) {
        let audiences = [...currentCampaign.audiences];
        let currentAudience = audiences[audienceIndex]
        let newAudienceParameters = currentAudience.parameters;
        newAudienceParameters.splice(paramIndex, 1);
        audiences[audienceIndex].parameters = newAudienceParameters
        handleChange({
            'target': {
                'name': 'audiences',
                'value': audiences
            }
        })
    }

    const [audienceHasErrors, setAudienceHasErrors] = useState(false)

    const validateAudience = () => {
        if(stepSuccession[activeStep] != STEPS.AUDIENCE) {
            return true
        }
debugger
        let requiredFields = stepRequiredInformationMap[stepSuccession[activeStep]];
        let newErrorFields = new Set(errorFields);
        let audienceErrorsFields = checkAudienceParameters(new Set());
        newErrorFields = new Set([...newErrorFields, ...audienceErrorsFields])

        for (let field of requiredFields) {
            if (!hasContent(currentCampaign[field])) {
                newErrorFields.add(field);
            } else {
                newErrorFields.delete(field)
            }
        }
        setAudienceHasErrors(audienceErrorsFields.size>0)
        setErrorFields(newErrorFields);

        return audienceErrorsFields.size==0
    }

    function allRequiredFieldsFilledCorrectly() {

        let requiredFields = stepRequiredInformationMap[stepSuccession[activeStep]];
        let newErrorFields = new Set(errorFields);
        // newErrorFields = checkCampaignExists(newErrorFields);
        newErrorFields = checkBasicStep(newErrorFields);
        newErrorFields = checkAudienceParameters(newErrorFields);
        newErrorFields = checkDates(newErrorFields);
        newErrorFields = checkDuration(newErrorFields);
        newErrorFields = checkRegexp(newErrorFields);

        for (let field of requiredFields) {
            if (!hasContent(currentCampaign[field])) {
                newErrorFields.add(field);
            } else {
                newErrorFields.delete(field)
            }
        }
        setErrorFields(newErrorFields);
        if (newErrorFields.size !== 0) return false;
        return !(stepSuccession[activeStep] === STEPS.AUDIENCE && currentCampaign.audiences.flatMap(a => a.parameters)
            .filter(p => p.operatorError === true ||
                p.valueError === true ||
                p.offerValueError === true ||
                p.offsetValueError === true
            ).length !== 0);

    }

    function checkBasicStep(errorFields) {
        if (currentCampaign.campaignId && currentCampaign.campaignId.includes('(Clone)')) {
            errorFields.add('(Clone)');
        } else {
            errorFields.delete('(Clone)');
        }
        return errorFields;
    }

    function handleInputId(e) {
        setCampaignForSearch(currentCampaign.campaignId)
    }

    function checkRegexp(newErrorFields) {
        debugger
        if (!props.template && stepSuccession[activeStep] === STEPS.STEP_ONE) {
            if (currentCampaign.regexp !== '*'  && !isEmpty(currentCampaign.regexp)) {
                let regexp = new RegExp(currentCampaign.regexp);
                if (!regexp.test(currentCampaign.campaignId)) {
                    newErrorFields.add('regexp')
                } else {
                    newErrorFields.delete('regexp')
                }
            }
        }
        return newErrorFields;
    }

    function findErrorAudiences() {
        let errorAudiences = [];
        debugger
        for (let a of currentCampaign.audiences) {
            for (let p of a.parameters) {
                if (p.offerValueError===true ||
                    p.offsetValueError===true ||
                    p.operatorError===true ||
                    p.valueError===true ||
                    p.paramNameError===true ||
                    (p.value!==undefined&&isEmpty(p.value))
                ) {
                    errorAudiences.push(currentCampaign.audiences.indexOf(a))
                }
            }
        }
        return errorAudiences;
    }

    function checkAudienceParameters(newErrorFields) {
        if (stepSuccession[activeStep] === STEPS.AUDIENCE) {
            currentCampaign.audiences.forEach((audience, audienceIndex) => {
                checkSameParameters(audience.parameters);
                audience.parameters.forEach((parameter) => {
                    if (parameter.hasOwnProperty('fromIncl') || parameter.hasOwnProperty('toIncl')) {
                        if (!parameter.paramFromValue && !parameter.paramToValue) return;
                        if ((parameter.parameterData.allowedRules.includes('VERSION_RANGE') &&
                                !validateVersion(parameter?.paramFromValue, parameter?.paramToValue)) ||
                            (parameter.parameterData.allowedRules.includes('NUMBER_RANGE') &&
                                !validateNumber(parameter?.paramFromValue, parameter?.paramToValue))
                        ) {
                            newErrorFields.add(
                                {
                                    audience: audienceIndex,
                                    parameterName: parameter.parameterData.parameterName
                                }
                            );
                        } else {
                            newErrorFields = new Set([...newErrorFields].filter(o => (o.audience === audienceIndex && o.parameterName !== parameter.parameterData.parameterName)));
                        }
                    } else if (["GREATER_THAN","LESS_THAN"].includes(parameter.operator)) {
                        debugger
                        parameter.offerValueError = !parameter.offerId
                        parameter.offsetValueError = !parameter.offset
                        parameter.operatorValueError = !parameter.operator
                        parameter.valueError = false
                        parameter.operatorError = false

                        if (parameter.offerId) {
                            parameter.offerValueError = false;
                        } else if (!parameter.offerId) {
                            parameter.offerValueError = true;
                        }else if (!parameter.operator) {
                            parameter.operatorValueError = true;
                        }
                        if (parameter.offset) {
                            parameter.offsetValueError = false;
                        } else if (!parameter.offset) {
                            parameter.offsetValueError = true;
                        }
                    } else {
                        parameter.operatorError = !parameter.operator;
                        parameter.valueError = !parameter.paramValue;
                        if (parameter.paramValue && parameter.paramValue.length === 0) {
                            parameter.valueError = true;
                        } else if (parameter.paramValue && parameter.paramValue.length > 0) {
                            parameter.valueError = false;
                        }
                    }
                })
            })
        }
        return newErrorFields;
    }

    function checkSameParameters(parameters) {
        let sameNameParams = findDuplicates(parameters.map(p => p.parameterData.parameterName));
        for (let p of parameters) {
            if (sameNameParams.includes(p.parameterData.parameterName)) {
                let filteredSameParameters = parameters.filter(parameter => parameter.parameterData.parameterName === p.parameterData.parameterName);
                let operators = findDuplicates(filteredSameParameters)
                p['paramNameError'] = operators.length > 0;
            } else {
                p['paramNameError'] = false;
            }
        }
    }

    function findDuplicates(params) {
        const uniq = params
            .map((param) => {
                return {
                    count: 1,
                    param: param
                };
            })
            .reduce((result, b) => {
                result[b.param] = (result[b.param] || 0) + b.count;

                return result;
            }, {});
        return Object.keys(uniq).filter((a) => uniq[a] > 1);
    }

    function checkDates(newErrorFields) {
        if (stepSuccession[activeStep] === STEPS.DISTRIBUTION) {
            if (currentCampaign.from.isAfter(currentCampaign.till) || currentCampaign.from.isSame(currentCampaign.till)) {
                newErrorFields.add('date')
            } else {
                newErrorFields.delete('date')
            }
        }
        return newErrorFields;
    }

    function checkDuration(newErrorFields) {
        if (stepSuccession[activeStep] === STEPS.DISTRIBUTION) {
            if ((currentCampaign.distributionType !== 'fixed'&&
                currentCampaign.distributionType !== 'permanent') && !hasContent(currentCampaign.durationHour)  && !/^\d+$/.test(currentCampaign.durationHour)
            ) {
                newErrorFields.add('durationHour')
            } else {
                newErrorFields.delete('durationHour')
            }
        }
        return newErrorFields;
    }

    useEffect(() => {
        //window.history.pushState({}, '', '#'+activeStep);
        console.log('activeStep')
        console.log(activeStep)
        if(stepSuccession[activeStep] == STEPS.AUDIENCE) {
            validateAudience()
            if(props.mode != MODE.CLONE && props.mode != MODE.UPDATE) setAudienceHasErrors(true)
            debugger
            //handleFinishStep()//validateAudience()
        }
    }, [activeStep])

    const handleFinishStep = () => {
        validateAudience()
        if (allRequiredFieldsFilledCorrectly()) {
            autocompleteSomeFields();
            if (stepSuccession[activeStep] === STEPS.AUDIENCE) {
                if (currentCampaign.audiences.flatMap(a => a.parameters).filter(p => p.paramNameError === true).length > 0) return
                setActiveStep(activeStep + 1);
            } else if (stepSuccession[activeStep] !== STEPS.FINAL_STEP) {
                setActiveStep(activeStep + 1);
            } else {
                prepareCampaignForSave()
            }
        }
        setErrorAudienceIndexes(findErrorAudiences())

    };

    function prepareParametersForSave(audienceForSave, parameterSet) {

        audienceForSave = Object.keys(audienceForSave).length === 0 ? new AudienceForSave() : audienceForSave;

        if (parameterSet.parameterData?.allowedRules?.includes('VERSION_RANGE')) {
            // if (!audienceForSave.versionRange[parameterSet.parameterData.parameterName]) {
            //     audienceForSave.versionRange[parameterSet.parameterData.parameterName] = new NumericRange()
            // }
            let numericRange = {
                parameter: parameterSet.parameterData.parameterName,
                from: parameterSet.paramFromValue,
                to: parameterSet.paramToValue,
                fromIncluded: parameterSet.fromIncl,
                toIncluded: parameterSet.toIncl
            }
            audienceForSave.versionRange.push(numericRange)
            // audienceForSave.versionRange[parameterSet.parameterData.parameterName].parameter = parameterSet.parameterData.parameterName;
            // audienceForSave.versionRange[parameterSet.parameterData.parameterName].from = parameterSet.paramFromValue;
            // audienceForSave.versionRange[parameterSet.parameterData.parameterName].to = parameterSet.paramToValue;
            // audienceForSave.versionRange[parameterSet.parameterData.parameterName].fromIncluded = parameterSet.fromIncl;
            // audienceForSave.versionRange[parameterSet.parameterData.parameterName].toIncluded = parameterSet.toIncl;

        } else if (parameterSet.parameterData?.allowedRules?.includes('NUMBER_RANGE')) {
            // if (!audienceForSave.numberRange[parameterSet.parameterData.parameterName]) {
            //     audienceForSave.numberRange[parameterSet.parameterData.parameterName] = new NumericRange()
            // }
            let numberRange = {
                parameter: parameterSet.parameterData.parameterName,
                from: parameterSet.paramFromValue,
                to: parameterSet.paramToValue,
                fromIncluded: parameterSet.fromIncl,
                toIncluded: parameterSet.toIncl
            }
            audienceForSave.numberRange.push(numberRange)
            // audienceForSave.numberRange[parameterSet.parameterData.parameterName].parameter = parameterSet.parameterData.parameterName;
            // audienceForSave.numberRange[parameterSet.parameterData.parameterName].from = parameterSet.paramFromValue;
            // audienceForSave.numberRange[parameterSet.parameterData.parameterName].to = parameterSet.paramToValue;
            // audienceForSave.numberRange[parameterSet.parameterData.parameterName].fromIncluded = parameterSet.fromIncl;
            // audienceForSave.numberRange[parameterSet.parameterData.parameterName].toIncluded = parameterSet.toIncl;

        } else if (
            parameterSet.parameterData?.allowedRules?.includes('INCLUDE') ||
            parameterSet.parameterData?.allowedRules?.includes('INCLUDE_ALL') ||
            parameterSet.parameterData?.allowedRules?.includes('INCLUDE_ANY') ||
            parameterSet.parameterData?.allowedRules?.includes('EXCLUDE_ANY') ||
            parameterSet.parameterData?.allowedRules?.includes('EXCLUDE_ALL') ||
            parameterSet.parameterData?.allowedRules?.includes('EXCLUDE')
        ) {

            //if (!audienceForSave[parameterSet.operator]?.[parameterSet.parameterData.parameterName]) {
                audienceForSave[parameterSet.operator].push({
                    parameter: parameterSet.parameterData.parameterName,
                    values: typeof parameterSet.paramValue === 'string' ? parameterSet.paramValue.split(',') :
                        parameterSet.paramValue
                })
            //}
        } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_IN_PROFILE')) {
            audienceForSave.offerInProfile.push({
                    parameter: 'offers_in_profile',
                    offerId: parameterSet.paramValue
                })
        } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_INACTIVE')) {
            audienceForSave.offerInactiveRule.push({
                    parameter: 'offer_inactive',
                    offerIds: parameterSet.paramValue
                })
        } else if (parameterSet.parameterData?.allowedRules?.includes('TYPE_INACTIVE')) {
            audienceForSave.offerTypeInactiveRule.push({
                    parameter: 'type_inactive',
                    types: parameterSet.paramValue
                })
        } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_NOT_IN_PROFILE')) {
            audienceForSave.offerNotInProfile.push({
                    parameter: 'offers_not_in_profile',
                    offerId: parameterSet.paramValue
            })
        } else if (parameterSet.parameterData?.allowedRules?.includes('PROFILE_OFFSET')) {
            let paramName = parameterSet.parameterData.parameterName

            audienceForSave.profileOffset.push({
                offerId: parameterSet.offerId,
                offset: parameterSet.offset,
                operator: parameterSet.operator,
                parameter: paramName
            })
        }

        checkOffersInAudience(audienceForSave)
        return audienceForSave;
    }

    function checkOffersInAudience(audienceForSave) {

        if (!audienceForSave.hasOwnProperty("profileOffset")) {
            audienceForSave.profileOffset = [];
        }
        if (!audienceForSave.hasOwnProperty("offerInProfile")) {
            audienceForSave.offerInProfile = [];
        }
        if (!audienceForSave.hasOwnProperty("offerNotInProfile")) {
            audienceForSave.offerNotInProfile = [];
        }
    }

    const prepareCampaignForSave = () => {

        let audiencesForSave = [];
        currentCampaign.audiences.forEach(audience => {
            let audienceForSave = {}
            for (let parameterSet of audience.parameters) {
                audienceForSave = prepareParametersForSave(audienceForSave, parameterSet)
            }
            audiencesForSave.push(audienceForSave)
        })

        let campaignForSave = {
            id: currentCampaign.campaignId,
            fickle: currentCampaign.fickle,
            repeatable: currentCampaign.repeatable,
            groupId: currentCampaign.groupId,
            priority: currentCampaign.priority,
            type: currentCampaign.offerType,
            bundleId: currentCampaign.bundleId,
            distribution: {
                type: currentCampaign.distributionType,
                durationHour: currentCampaign.durationHour ? currentCampaign.durationHour : "",
                from: currentCampaign.from.valueOf()/1000,
                till: currentCampaign.till.valueOf()/1000
            },
            audiences: audiencesForSave
        }
        if(!isEmpty(currentCampaign.matchmakingType)) {
            campaignForSave['matchmakingType'] = currentCampaign.matchmakingType
        }

        if(props.template) {
            campaignForSave.schedule = {
                delayDays: currentCampaign.schedule.delayDays,
                till: currentCampaign.schedule.till!=null?currentCampaign.schedule.till.valueOf()/1000:null
            }
        }
        setBeforeSaveDialogOpen(true);
        setCampaignForSave(campaignForSave);
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        setErrorDialogOpen(false);
    }

    const handleCreateAnotherCampaign = () => {
        let curr = window.location.pathname;

        if(props.template) {
            if ('/template' === curr) {
                window.location.reload();
            } else {
                navigate(`/creator/templates?env=${getEnvName('env', sourceMode, searchParams)}`)
            }
        } else {
            if ('/campaign' === curr) {
                window.location.reload();
            } else {
                navigate(`/creator/campaigns?env=${getEnvName('env', sourceMode, searchParams)}`)
            }
        }

    }


    const handleGoToPublisher = () => {
        let curr = window.location.pathname;
        if ('/publisher' === curr) {
            window.location.reload();
        } else {
            if(props.template) {
                navigate(`/publisher/templates?env=${sourceMode.env1Name}&env2=${sourceMode.env2Name}`)
            } else {
                navigate(`/publisher/campaigns?env=${sourceMode.env1Name}&env2=${sourceMode.env2Name}`)
            }
        }
    }

    const handleVersionNumericParameterChange = (field, value, paramIndex, audienceIndex) => {
        let p = getCurrentParam(currentCampaign, paramIndex, audienceIndex);
        const newCurrentCampaign = {...currentCampaign};
        newCurrentCampaign.audiences[audienceIndex].parameters[paramIndex][field] = value;
        setCurrentCampaign(newCurrentCampaign);
    }


    function getStepContent(step) {
        switch (stepSuccession[step]) {
            case STEPS.STEP_ONE:
                return <BasicStep
                    template={props.template}
                    mode={props.mode}
                    types={types}
                    status={typeStatus}
                    currentCampaign={currentCampaign}
                    setCurrentCampaign={setCurrentCampaign}
                    handleChange={handleChange}
                    handleInput={handleInputId}
                    handleBundleChange={handleBundleChange}
                    setTypeParameters={setTypeParameters}
                    handleOfferTypeChange={handleOfferTypeChange}
                    handleMatchmakingTypeChange={handleMatchmakingTypeChange}
                    handleBundleManualInputChange={handleBundleManualInputChange}
                    errorFields={errorFields}
                />;
            case STEPS.DISTRIBUTION:
                return <DateStep
                    currentCampaign={currentCampaign}
                    setCurrentCampaign={setCurrentCampaign}
                    errorFields={errorFields}
                    setErrorFeilds={setErrorFields}
                    handleChange={handleChange}
                    typeParameters={typeParameters}
                    handleDateChange={handleDateChange}
                    handleTimeZoneChange={handleTimeZoneChange}
                    template={props.template}
                    handleChangeScheduleTill={handleChangeScheduleTill}
                    handleChangeSchedule={handleChangeSchedule}


                />;
            case STEPS.AUDIENCE:
                return <AudienceStep
                    errorAudienceIndexes={errorAudienceIndexes}
                    currentCampaign={currentCampaign}
                    setCurrentCampaign={setCurrentCampaign}
                    errorFields={errorFields}
                    handleChange={handleChange}
                    setErrorFields={setErrorFields}
                    handleManualInput={handleManualInput}
                    handleAddNewParams={handleAddNewParams}
                    handleDeleteParams={handleDeleteParams}
                    handleAddNewAudience={handleAddNewAudience}
                    handleDeleteAudience={handleDeleteAudience}
                    handleParameterDataChange={handleParameterDataChange}
                    handleManualInputForOffset={handleManualInputForOffset}
                    handleParameterValueChange={handleParameterValueChange}
                    handleParameterOperatorChange={handleParameterOperatorChange}
                    handleVersionNumericParameterChange={handleVersionNumericParameterChange}
                    isTemplate={props.template}
                />;
            case STEPS.FINAL_STEP:
                return <SummaryStep
                    isTemplate={props.template}
                    currentCampaign={currentCampaign}
                />;
            default:
                throw new Error('Unknown step');
        }
    }

    const typeTitle = props.template ? 'TEMPLATE' : 'CAMPAIGN';

    const formTitle = <><span style={{color: getColor(props.mode)}}>{props.template?'TEMPLATE ':''}{props.template&&props.mode==MODE.POLY ?' FROM ' : props.mode.toUpperCase()} {props.mode === MODE.CREATE ? ' ' + typeTitle : ''}</span> {props.title ? props.title : ''}</>

    const getErrorText = () => {
        if(audienceHasErrors) return 'Audience has errors'
        if(!currentCampaign.campaignId) return 'CampaignId is empty'
        if(inputNotVerified) return 'inputNotVerified'
        if(interactionTypeFilled) {
            if(types==undefined) return 'types not loaded'
            if(currentCampaign!=undefined&&!types.hasOwnProperty(currentCampaign.offerType)) return 'no offer type';
            if(types[currentCampaign.offerType].interactionType=='TOURNAMENT'
                    &&isEmpty(currentCampaign.matchmakingType)
                    &&types[currentCampaign.offerType]?.matchmakingTypes!=null
                    &&types[currentCampaign.offerType]?.matchmakingTypes.length>0
            ) return ''
            return ''
        }
        if(isEmpty(currentCampaign.bundleId)) return 'Empty bundle id'
        if(isLoading) return 'isLoading'
        if((isError && !error.data?.message?.includes('not found'))) return '(isError && !error.data?.message?.includes(\'not found\'))'
        if(isSuccess) return 'isSuccess'

        return ''
    }

    const handleSave = async () => {
debugger
        let saveParams = {
            ev: getEnvName('env', sourceMode, searchParams),
            body: campaignForSave
        }

        if(searchParams.get('clone')!==null) {
            saveParams['original'] = params.id
        }

        if(props.template) {
            saveTemplateCampaign(saveParams)
        }
        else {
            saveCampaign(saveParams)
        }
        setBeforeSaveDialogOpen(false)

    }

    return (
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <Box component="main" maxWidth="md" sx={{mb: 4}} display={'grid'}>
                    <Paper
                        variant="outlined" sx={{my: {xs: 3, md: 2}, p: {xs: 2, md: 3}}}>
                        <Typography component="h1" variant="h4" align="center">
                            {formTitle}
                        </Typography>
                        <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                            {stepSuccession.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Fragment>
                            {getStepContent(activeStep)}


                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{}}>
                                        Back
                                    </Button>
                                )}
                                {activeStep == 0 &&
                                    <>{(searchParams.get('mode'))?<Button
                                        color={"warning"}
                                        variant={"contained"}
                                        onClick={() => navigate(`/variants`)}>
                                        Back
                                    </Button>:<Button
                                        color={"warning"}
                                        variant={"contained"}
                                        onClick={() => navigate(`/publisher/`+(props.template?`templates`:`campaigns`)+`?env=${sourceMode.env1Name}&env2=${sourceMode.env2Name}`)}>
                                        To Publisher
                                    </Button>}</>
                                }

                                {!props.template&&activeStep == 0 && (
                                    <Button
                                        color={"error"}
                                        variant={"contained"}
                                        onClick={() => document.location.href = `/creator/templates/`+props.title+`?campaign&env=`+getEnvName('env', sourceMode, searchParams)}>
                                        Create template
                                    </Button>
                                )}

                                <Box>
                                    <LoadingButton
                                        loading={responseObject && responseObject.isLoading || inputNotVerified || isLoading
                                    }
                                        variant="contained"
                                        onClick={() => {
                                            handleFinishStep()
                                        }}
                                        disabled={props.mode !== MODE.UPDATE &&
                                            ((audienceHasErrors&&activeStep==2) || !currentCampaign.campaignId ||
                                                inputNotVerified ||
                                                interactionTypeFilled ||
                                                isEmpty(currentCampaign.bundleId) ||
                                                isLoading ||
                                                (isError && !error.data?.message?.includes('not found')) ||
                                                isSuccess)}
                                    >
                                        {stepSuccession[activeStep] === STEPS.FINAL_STEP ? 'SAVE' : 'NEXT'}
                                    </LoadingButton>
                                </Box>
                            </Box>

                        </Fragment>
                        <Dialog
                            open={successDialogOpen}
                            onClose={() => handleDialogClose()}
                            scroll={"paper"}

                        >
                            <DialogTitle>
                                {props.template?"Template saved":"Campaign saved"}
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText id="alert-dialog-description">
                                    <JsonViewer json={responseObject.data}></JsonViewer>
                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCreateAnotherCampaign()}>
                                    Create another {props.template?'template':'campaign'}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleGoToPublisher()}>

                                    Go to publisher
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={errorDialogOpen} onClose={() => handleDialogClose()}>
                            <Box minWidth={300} minHeight={300}>
                                <DialogTitle>
                                    {"Error"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {responseObject?.error?.data?.message}
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button
                                            onClick={() => handleDialogClose()}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Box>
                        </Dialog>
                        <Dialog
                            scroll={"paper"}
                            open={beforeSaveDialogOpen} onClose={() => setBeforeSaveDialogOpen(false)}>
                            <DialogTitle>{`Going to save campaign ${campaignForSave.id}`}</DialogTitle>
                            <DialogContent dividers={true}>
                                <Box>
                                    <ReactJson
                                        theme={'bright:inverted'}
                                        src={campaignForSave}
                                        iconStyle={'square'}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        enableClipboard={false}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>

                                <Button variant="outlined"
                                        onClick={() => setBeforeSaveDialogOpen(false)}>
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleSave}>
                                    Save
                                </Button>
                            </DialogActions>


                        </Dialog>
                    </Paper>

                </Box>
            </Box>
        </>
    );
}