import React, {useEffect, useState, useContext} from 'react';

import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    FormControlLabel, Grid,
    IconButton,
    Switch,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import {styled} from "@mui/system";
import logoImg from '../../../src/imgs/logo.png';
import {Link, useLocation, useSearchParams} from "react-router-dom";
import DehazeIcon from '@mui/icons-material/Dehaze';
import SplitButton from "../SplitButton";
import {useDispatch, useSelector} from "react-redux";



import {useGetAllEnvironmentsQuery} from "../../core/reducers/environments";
import {
    CAMPAIGNS,
    DEFAULT_ENV,
    DEFAULT_ENV2,
    getEnvName, GROUP_ADMIN, isAdmin, isNeedToShowMode, isProdViewer,
    LIVEOPS_ENV,
    PROD
} from "../Utils/CommonUtils";
import {SnackBarContext} from "../../core/context/snackBarProvider";

import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";

import * as Sentry from "@sentry/react";
import {LOCATION_CHANGE} from "react-router-redux/lib/reducer";


const ToolbarStaging = styled(Toolbar)({
    backgroundColor: "rgb(3,144,127)",
    background: "linear-gradient(90deg, rgba(3,144,127,1) 0%, rgba(116,237,223,1) 35%, rgba(255,255,255,1) 100%)",
});

const ToolbarProd = styled(Toolbar)({
    backgroundColor: "rgb(231,68,21)",
    background: "linear-gradient(90deg, rgba(217,120,92,1) 0%, rgba(249,181,126,1) 35%, rgba(251,249,245,1) 100%)"
});

export default function Header(props) {
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...{},
                redirectUri: '/dummy.html',
            })
            // .then((data) => {
            //     console.log("AUTH DONE")
            //     console.log(data)
            //     dispatch({
            //         type: 'AUTH_SELF_SUCCESS', payload: {
            //             data: data.account.idTokenClaims.roles
            //         }
            //     });
            //     localStorage.setItem("azureToken", data.idToken)
            // })
            .catch((error) => console.log(error));
    };

    const handleLoginRedirect = () => {
        instance.loginRedirect({}).catch((error) => console.log(error));
    };

    const handleLogoutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: '/', // redirects the top level app after logout
                account: instance.getActiveAccount(),
            })
            .then(() => {

            })
            .catch((error) => {
                console.log("LOGOUT")
                console.log(error)
            });
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    const {open, handleDrawerOpen} = props;
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const sessionState = useSelector((state) => state.data.SourceMode);
    const userSession = useSelector((state) => state.data.UserSession);

    const env1Name = sessionState.env1Name;
    const env2Name = sessionState.env2Name;
    const appMode = sessionState.mode;
    const isShowMode = isNeedToShowMode();

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const envValue = queryParams.get("env");
    console.log('location')
    console.log(location)

    useEffect(() => {
        if(state.isProd) {
            if(['/campaignType','/environment','/parameter','/search',"/creator",'/variants'].indexOf(location.pathname) >= 0) {
                switchToDev()
            }
        }


    },[location])

    const {
        data: environments,
    } = useGetAllEnvironmentsQuery()
    let evList = [];

    if (environments !== undefined) {
        if(location.pathname.indexOf('/variants') >= 0) {
            evList = ['liveops'];

        } else {
            evList = Object.keys(environments);
        }
        localStorage.setItem("envList", JSON.stringify(environments))

    }

    const [state, setState] = useState({
        isProd: sessionState.isProd,
        authenticated: localStorage.getItem('authenticated') !== undefined ? localStorage.getItem('authenticated') : false,
        env1BeforeSwitch: DEFAULT_ENV,
        env2BeforeSwitch: DEFAULT_ENV2,
    });

    const ev = env1Name

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const switchToProd = () => {
        setState({
            ...state,
            isProd: true,
            isStage: false,
            env1BeforeSwitch: env1Name,
            env2BeforeSwitch: env2Name
        });
        setSearchParams({env: LIVEOPS_ENV, env2: PROD})
        dispatch({
            type: 'IS_PROD', payload: {
                data: {
                    isProd: true
                }
            }
        });

        dispatch({
            type: 'EV1_NAME', payload: {
                data: {
                    env1Name: LIVEOPS_ENV
                }
            }
        });
        dispatch({
            type: 'EV2_NAME', payload: {
                data: {
                    env2Name: PROD
                }
            }
        });
    }
    const switchToDev = () => {
        dispatch({
            type: 'IS_PROD', payload: {
                data: {
                    isProd: false
                }
            }
        });
        switchEnv(state.env1BeforeSwitch, state.env2BeforeSwitch);
        setSearchParams({env: state.env1BeforeSwitch, env2: state.env2BeforeSwitch})
    }


    const goToProd = () => {
        if (window.location.pathname.indexOf("/creator") >= 0) {
            addAlert({text: 'You can\'t change environment while creating campaign', type: 'warning'})
            return false;
        }
        if (location.pathname.indexOf('/search') >= 0) {
            addAlert({text: 'You can\'t change environment while searching', type: 'warning'})
            return false;
        }
        if(['/campaignType','/environment','/parameter'].indexOf(location.pathname) >= 0) {
            addAlert({text: 'You can\'t change environment here', type: 'warning'})
            return false;
        }

        state.isProd ? switchToDev() :switchToProd();
    }


    const switchEnv = (env1, env2) => {
        console.log("switchEnv")
        console.log(env1Name)
        console.log(env2Name)
        setState({
            ...state,
            isProd: false,
            env1BeforeSwitch: env1Name,
            env2BeforeSwitch: env2Name
        });

        if (env2 !== undefined) {
            setSearchParams({env: env1, env2: env2});
        } else {
            setSearchParams({env: env1});

        }

        dispatch({
            type: 'EV1_NAME', payload: {
                data: {
                    env1Name: env1
                }
            },
        });
        dispatch({
            type: 'EV2_NAME', payload: {
                data: {
                    env2Name: env2 !== undefined ? env2 : sessionState.env2Name
                }
            },
        });

        //localStorage.setItem('prevEvn', getFromStorage('envName'))
        //localStorage.setItem('envName', envName)

        document.location.reload();
    }


    useEffect(() => {
        if(['/campaignType','/environment','/parameter'].indexOf(location.pathname) >= 0) {
            if(state.isProd) switchToDev()
        }
        setState({
            ...state,
            isProd: getEnvName('env2', sessionState, searchParams) === PROD &&
                !(location.pathname.indexOf("/search") >=0),
            authenticated: true
        });
        localStorage.setItem('authenticated', true)

        if (location.pathname.indexOf("/search") >=0) {
            dispatch({
                type: 'EV2_NAME', payload: {
                    data: {
                        env2Name: env1Name
                    }
                }
            });
        }

    }, [location])


    const loginContent = () => {
            if(userSession.authenticated) {
                return (
                    <Button color="warning" variant="outlined" >Logout</Button>
                )
            } else {
                return (
                    <Button color="warning" variant={"contained"} >Login</Button>
                );
            }

            // case AuthenticationState.InProgress:
            //     return (<p>Authenticating...<Link onClick={authProvider.login}>login</Link></p>);
            // default:
            //     return (<Link onClick={authProvider.login}>login</Link>)

    }
    console.log('state.isProd', state.isProd)
    console.log('userSession')
    console.log(userSession)

    const modeTitles = {
        "campaigns":".Campaigns",
        "templates":".Templates",
        "abtests": ".AB tests"
    }

    const toolbarContent = <Grid container alignItems={'center'}>
        <Grid item sm={0.25}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{mr: 2, ...(open && {display: 'none'})}}
            >
                <DehazeIcon sx={{color: "#000000"}}/>
            </IconButton>
        </Grid>
        <Grid item sm={4.75}>
            <Typography color="black"
                        component="div"
                        variant="h6"
            >
                Mephisto{isShowMode ? <strong>{modeTitles[appMode]}</strong> : <></>}
            </Typography>
        </Grid>

        <Grid item sm={2} justifyContent={'center'} display={'flex'}>
            {(location.pathname.indexOf('/creator') >= 0 || location.pathname.indexOf('/variants') >= 0 ) && <Box><strong>{envValue}</strong></Box>}
            {evList.length > 0 && (
                location.pathname.indexOf('/search') >= 0 ||
                location.pathname.indexOf('/logs') >= 0
                ) &&
                <SplitButton options={environments}
                             switchEnv={switchEnv}
                             color={state.isProd ? "warning" : "success"}
                             variant={"contained"}
                />
            }

        </Grid>
        <Grid item sm={4} display={'flex'} justifyContent={'end'}>
            {!(location.pathname.indexOf('/variants')>=0)&&<Box>
                {userSession.authenticated &&
                    userSession.user!=null && (isAdmin(userSession) || isProdViewer(userSession)) &&
                    <FormControlLabel sx={{ml: 1}} onClick={goToProd}
                                      control={<Switch color={"warning"} checked={state.isProd}/>}
                                      label={state.isProd ? "Prod" : "Dev"}/>
                }
            </Box>}
        </Grid>
        <Grid item sm={1} display={'flex'} justifyContent={'end'}>
            <Box sx={{display: {md: 'flex'}, ml: 1}}>
                <AuthenticatedTemplate>
                    <Button variant={"outlined"} color="warning" onClick={handleLogoutPopup}>
                        Logout
                    </Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Button variant={"contained"} color="warning" onClick={handleLoginPopup}>
                        Sign in
                    </Button>
                </UnauthenticatedTemplate>
            </Box>
        </Grid>
    </Grid>

    return (
        <Box className="header" sx={{flexGrow: 1}} pb={3}>
            <CssBaseline/>
            <AppBar position="fixed" open={open} id={'test'}>
                {state.isProd ? (
                    <ToolbarProd>
                        {toolbarContent}
                    </ToolbarProd>
                ) : (
                    <ToolbarStaging>
                        {toolbarContent}
                    </ToolbarStaging>
                )}
            </AppBar>


        </Box>

    );
}


