import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import ReactJson from "react-json-view";
import {getEnvName} from "../Utils/CommonUtils";
import {useSelector} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import {useSaveAbTestMutation} from "../../core/reducers/abtest";
import {useEffect} from "react";
import {LoadingButton} from "@mui/lab";
import {useSaveCampaignMutation} from "../../core/reducers/campaign";

export default function BeforeSaveDialog({cohorts, updateCampaigns, beforeSaveDialogOpen, setBeforeSaveDialogOpen, abTestForSave, setSaveAbTestResponse}) {
    const params = useParams();

    const [saveAbTest, saveAbTestResponse] = useSaveAbTestMutation();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [searchParams, setSearchParams] = useSearchParams();

    const [saveCampaign, saveCampaignResponse] = useSaveCampaignMutation();

    const handleSave = async () => {

        let saveParams = {
            env: getEnvName('env', sourceMode, searchParams),
            body: abTestForSave
        }

        if(searchParams.get('clone')!==null) {
            saveParams['original'] = params.id
        }

        let response = await saveAbTest(saveParams)

        debugger
        if(updateCampaigns!=undefined && updateCampaigns==true) {
            for(let item of cohorts) {
                let campaign = structuredClone(item.campaign)

                campaign.groupId = cohorts[0].campaign.groupId
                for(let index in campaign.audiences) {
                    if(!campaign.audiences[index].hasOwnProperty('includeAll')) {
                        campaign.audiences[index]['includeAll'] = []
                    }

                    let newCohortName = abTestForSave.id + ":" + item.name
                    let cohortFound = false;

                    for(let ia in campaign.audiences[index].includeAll) {
                        if(campaign.audiences[index].includeAll[ia].parameter == "ab_test_cohort") {
                            campaign.audiences[index].includeAll[ia].values.push(newCohortName)
                            cohortFound = true;
                            break
                        }
                    }
                    if(!cohortFound) {
                        campaign.audiences[index].includeAll.push({
                            "parameter":"ab_test_cohort",
                            "values":[newCohortName]
                        })
                    }


                }
                let response = await saveCampaign({
                    ev: getEnvName('env', sourceMode, searchParams),
                    body: campaign
                })
                console.log('response saveCampaign')
                console.log(response)
            }
        }
        setBeforeSaveDialogOpen(false)
    }

    useEffect(() => {
        if (saveAbTestResponse) {
            setSaveAbTestResponse(saveAbTestResponse)
        }
    }, [saveAbTestResponse])

    return <Dialog
        scroll={"paper"}
        open={beforeSaveDialogOpen} onClose={() => setBeforeSaveDialogOpen(false)}>
        <DialogTitle>{`Going to save AB Test ${abTestForSave.id}`}</DialogTitle>
        <DialogContent dividers={true}>
            <Box>
                <ReactJson
                    theme={'bright:inverted'}
                    src={abTestForSave}
                    iconStyle={'square'}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                />
            </Box>
        </DialogContent>
        <DialogActions>

            <Button variant="outlined"
                    onClick={() => setBeforeSaveDialogOpen(false)}>
                Cancel
            </Button>

            <LoadingButton
                loading={saveAbTestResponse.isLoading}
                variant="contained"
                onClick={() => handleSave()}>
                Save
            </LoadingButton>
        </DialogActions>


    </Dialog>
}