import {
    Autocomplete,
    Box, Button,
    Checkbox,
    FormControlLabel,
    Grid, IconButton,
    ListSubheader,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {Fragment, useContext, useEffect, useId, useState} from "react";
import {CohortContext} from "../../../core/context/context";
import {isValidInteger, isEmpty, isValidJson, getEnvName} from "../../Utils/CommonUtils";
import {CohortError} from "../AbTestForm";
import {v4 as uuid} from "uuid";
import {getCurrentParam} from "../../Campaign/CampaignForm";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useGetAllTemplatesQuery} from "../../../core/reducers/templates";
import {useGetAllCampaignsQuery} from "../../../core/reducers/campaign";
import Loading from "../../Loading";
import {isNumber} from "@mui/x-data-grid/internals";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";


export default function CohortSimple({cohortList, setCohortList, setHasErrors}) {
    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);

    const [errors, setErrors] = useState({});

    const query = {}
    query.env = envName
    //query.from = Math.round(new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).getTime()/100)*100
    //query.till = Math.round(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).getTime()/100)*100
    query.from = Math.round(new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).setHours(0, 0, 0, 0))/1000
    query.till = Math.round(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).setHours(0, 0, 0, 0))/1000

    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllCampaignsQuery(query, {
    });

    const handleNameChange = (index, value) => {
        const updatedList = [...cohortList];
        updatedList[index].name = value;
        setCohortList(updatedList);

        // Check for duplicate names
        const nameCount = updatedList.filter(item => item.name === value).length;
        if (nameCount > 1) {
            setErrors(prev => ({ ...prev, ['cohort_'+index]: 'Имя должно быть уникальным' }));
        } else if(!/^[A-Za-z_\d]+$/.test(value)) {
            setErrors(prev => ({ ...prev, ['cohort_'+index]: '[A-z0-9_]' }));
        } else {
            setErrors(prev => ({ ...prev, ['cohort_'+index]: null }));
        }

    };
    useEffect(() => {
        let hasErrors = false;
        Object.keys(errors).forEach((index) => {
                if(errors[index] != null) hasErrors = true
            })
        setHasErrors(hasErrors)
    },[errors])

    const handleWeightChange = (index, value) => {
        const updatedList = [...cohortList];
        updatedList[index].weight = value;
        setCohortList(updatedList);

        if(value < 0) {
            setErrors(prev => ({ ...prev, ['weight_'+index]: '>=0' }));
        }else {
            setErrors(prev => ({ ...prev, ['weight_'+index]: null }));
        }
    };

    const handleCampaignChange = (index, value) => {
        const updatedList = [...cohortList];
        updatedList[index].campaign = value;
        setCohortList(updatedList);
    };

    const handleDeleteRow = (index) => {
        if (cohortList[index].removable) {  // Проверяем, можно ли удалить
            const updatedList = cohortList.filter((_, i) => i !== index);
            setCohortList(updatedList);
        }
    };

    const handleAddRow = () => {
        setCohortList([...cohortList, { name: '', weight: 1, campaign: null, removable: true }]);
    };

    return (
        <Loading isLoading={isLoading} isError={isError} error={error}>
            {campaigns != undefined &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width:'150px'}}>Cohort</TableCell>
                                <TableCell sx={{width:'100px'}}>Weight</TableCell>
                                <TableCell sx={{width:'400px'}}>Campaign</TableCell>
                                <TableCell sx={{width:'50px'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cohortList.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={item.name}
                                            onChange={(e) => handleNameChange(index, e.target.value)}
                                            label="Cohort"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors['cohort_'+index]}
                                            helperText={errors['cohort_'+index]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={item.weight}
                                            onChange={(e) => handleWeightChange(index, e.target.value)}
                                            label="Weight"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors['weight_'+index]}
                                            helperText={errors['weight_'+index]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            fullWidth={true}
                                            value={item.campaign}
                                            loading={isLoading}
                                            options={campaigns.campaigns}
                                            onChange={(event, newValue) => handleCampaignChange(index, newValue)}

                                            getOptionLabel={(option) => option.id}
                                            renderInput={(params) => <TextField {...params} label="Search"
                                                                                variant="outlined"/>}
                                            filterOptions={(options, state) =>
                                                options.filter((option) => option.id.toLowerCase().includes(state.inputValue.toLowerCase()))
                                            }
                                        />

                                    </TableCell>
                                    <TableCell>
                                        {item.removable &&
                                            <IconButton onClick={() => handleDeleteRow(index)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>
            }
            <Button onClick={handleAddRow} variant="outlined" sx={{mb:2,mt:2}}>
                <AddIcon />

            </Button>
        </Loading>
    );
}